<template>
  <div class="container-top">
    <div class="pc">
      <div class="banner">
        <div class="container">
          <div class="circle"></div>
        </div>
        <div class="banner-content">
          <div class="col-5 flex-align">
            <div>
              <div class="flex h2"><div>내 비즈니스의 <br> 든든한 파트너, 런치팩</div>
              </div>
              <div class="body0-medium" style="margin-top:12px">디자이너, 개발자 없이도 내 서비스 운영 가능!</div>
            </div>
          </div>
          <div class="col-7 flex" style="align-items: flex-end">
            <img src="/static/img/guide/img_page_design_market-02.png" alt="">
          </div>
        </div>
      </div>

      <!-- 메뉴 -->
      <div class="flex-center cat-wrapper" v-if="filtered.selectedMenu">
        <div v-for="menu in menus"
             :key="'menu-'+menu.id"
             class="cat-st subtitle5 unselect"
             :class="{'primary':filtered.selectedMenu.id===menu.id}"
             @click="clickMenu(menu)">
          {{ menu.label }}
          <div :class="{'cat-selected':filtered.selectedMenu.id===menu.id}"></div>
        </div>
      </div>

      <div class="container">
        <!-- 전체가 아닐경우 -->
        <div v-if="filtered.selectedMenu && filtered.selectedMenu.label!=='전체'">
          <div class="h5 padding-top-60 padding-bottom-28">
            {{ filtered.selectedMenu.label==='전체'?'전체스킨':filtered.selectedMenu.label }}
          </div>
          <CardProducts :shadow="true" :products="list"></CardProducts>
        </div>
        <!-- 전체일 경우 -->
        <div v-else>
          <template v-for="(item, item_idx) in menus.slice(1,menus.length)">
            <div class="product-item-wrapper" v-if="products[`products${item_idx}`] && products[`products${item_idx}`].data.length>0" :key="`menu-${item_idx}`">
              <div class="flex-between padding-top-60 padding-bottom-28">
                <div class="unselect" @click="clickMenu(item)">
                  <span class="h5">{{ item.label }}</span>
                  <span class="h5 primary margin-left-4">{{ products[`products${item_idx}`].count }}</span>
                </div>
                <div class="body2-medium primary unselect" @click="clickMenu(item)">더보기</div>
              </div>
              <CardProducts :shadow="true" :products="products[`products${item_idx}`].data"></CardProducts>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="tablet">
      <div class="banner">
        <div class="container">
          <div class="circle"></div>
        </div>
        <div class="banner-content">
          <div class="h6"><div>내 비즈니스의 <br> 든든한 파트너, 런치팩</div>
          </div>
          <div class="body4" style="margin-top:12px">디자이너, 개발자 없이도 내 서비스 운영 가능!</div>
        </div>
      </div>

      <!-- 메뉴 -->
      <div class="cat-wrapper" v-if="filtered.selectedMenu">
        <div v-for="menu in menus"
             :key="'menu-'+menu.id"
             class="cat-st subtitle7 unselect"
             @click="clickMenu(menu)">
          {{ menu.label }}
          <div :class="{'cat-selected':filtered.selectedMenu.id===menu.id}"></div>
        </div>
      </div>

      <div class="container">
        <!-- 전체가 아닐경우 -->
        <div v-if="filtered.selectedMenu && filtered.selectedMenu.label!=='전체'">
          <div class="h7 padding-top-32 padding-bottom-8">
            {{ filtered.selectedMenu.label==='전체'?'전체스킨':filtered.selectedMenu.label }}
          </div>
          <CardProducts :shadow="true" :products="list"></CardProducts>
        </div>
        <!-- 전체일 경우 -->
        <div v-else>
          <template v-for="(item, item_idx) in menus.slice(1,menus.length)">
            <div class="product-item-wrapper" v-if="products[`products${item_idx}`] && products[`products${item_idx}`].data.length>0" :key="`menu-${item_idx}`">
              <div class="unselect padding-top-32 padding-bottom-16" @click="clickMenu(item)">
                <span class="h7">{{ item.label }}</span>
                <span class="h7 primary margin-left-2">{{ products[`products${item_idx}`].count }}</span>
              </div>
              <CardProducts :shadow="true" :products="products[`products${item_idx}`].data"></CardProducts>
              <button class="mobile-more-btn body2-medium"
                      style="width:100%;height:48px"
                      @click="clickMenu(item)">{{ item.label }} 더보기</button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="banner">
        <div class="container">
          <div class="circle"></div>
        </div>
        <div class="banner-content">
          <div class="h6"><div>내 비즈니스의 <br> 든든한 파트너, 런치팩</div>
          </div>
          <div class="body4" style="margin-top:12px">디자이너, 개발자 없이도 내 서비스 운영 가능!</div>
        </div>
      </div>

      <!-- 메뉴 -->
      <div class="cat-wrapper" v-if="filtered.selectedMenu">
        <div v-for="menu in menus"
             :key="'menu-'+menu.id"
             class="cat-st subtitle7 unselect"
             @click="clickMenu(menu)">
          {{ menu.label }}
          <div :class="{'cat-selected':filtered.selectedMenu.id===menu.id}"></div>
        </div>
      </div>

      <div class="container">
        <!-- 전체가 아닐경우 -->
        <div v-if="filtered.selectedMenu && filtered.selectedMenu.label!=='전체'">
          <div class="h7 padding-top-32 padding-bottom-8">
            {{ filtered.selectedMenu.label==='전체'?'전체스킨':filtered.selectedMenu.label }}
          </div>
          <CardProducts :shadow="true" :products="list"></CardProducts>
        </div>
        <!-- 전체일 경우 -->
        <div v-else>
          <template v-for="(item, item_idx) in menus.slice(1,menus.length)">
            <div class="product-item-wrapper" v-if="products[`products${item_idx}`] && products[`products${item_idx}`].data.length>0" :key="`menu-${item_idx}`">
              <div class="unselect padding-top-32 padding-bottom-8" @click="clickMenu(item)">
                <span class="h7">{{ item.label }}</span>
                <span class="h7 primary margin-left-2">{{ products[`products${item_idx}`].count }}</span>
              </div>
              <CardProducts :shadow="true" :products="products[`products${item_idx}`].data"></CardProducts>
              <button class="mobile-more-btn body2-medium"
                      style="width:100%;height:48px"
                      @click="clickMenu(item)">{{ item.label }} 더보기</button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ListMixin from "../../mixins/ListMixin";
  import CardProducts from "../component/CardProducts";

  export default {
    components: {
      CardProducts
    },
    name: "Store",
    mixins: [
      ListMixin
    ],
    data() {

      return {
        list: [],
        filtered: {
          selectedMenu: undefined
        },
        menus: [],
        recent:false,
        products: {
          products0: undefined,
          products1: undefined,
          products2: undefined,
          products3: undefined,
          products4: undefined,
          products5: undefined,
          products6: undefined,
          products7: undefined,
          products8: undefined,
          products9: undefined
        }
      }
    },
    created() {
      this.pageLoadType = 'infinite';
      this.init();
    },
    methods: {
      modalOpen(){
        this.setGa('주문제작 신청하기 (하단배너)', '클릭', '주문제작 신청하기');
        this.$refs.modal.open();
      },
      // 최신순
      ordering() {
        this.recent?this.recent=false:this.recent=true;
        let ordering = '';
        if(this.recent) ordering = 'id';
        else ordering = '-id';
        this.filtered.ordering = ordering;
      },
      // 탭 선택
      clickMenu(menu) {
        this.setGa(`테마 카테고리(${menu.label})`, '클릭', menu.label);
        this.getMenu(menu);
        window.scrollTo(0, 0);
      },
      getMenu(menu) {
        this.current_page = 1;
        this.filtered.page_num = 1;
        this.filtered.selectedMenu = menu;
        if(this.filtered.selectedMenu.id===0) {
          this.getProductsFilter();
        } else {
          this.getProducts();
        }
      },
      init() {
        let url = 'public/category?parent_id=39'
        // let url = 'public/launchpack/theme/category'
        this.$axios.get(url).then(res => {
          if (res.status === 200) {
            let menus = [{
                desc: "",
                img: "",
                id: 0,
                label: "전체"
              }]
            ;
            menus = menus.concat(res.data);
            this.menus = menus;
            this.menus[0].label = '전체';

            if(this.$route.query.tab) {
              let menu = this.findItem(this.menus, 'id', parseInt(this.$route.query.tab));
              if(menu) {
                this.clickMenu(menu);
                this.filtered.selectedMenu = menu;
              }
            } else {
              this.filtered.selectedMenu = this.menus[0];
              this.getProductsFilter();
            }
          }
        })
      },
      getProductsFilter() {
        let fields = '&fields=id,name,simple_desc,img,price,partner.img,partner.name,category1,category2,preview_url';
        let url = `user/0/mapping/product?${fields}`
        let cats = this.menus.filter(menu => { return menu.id > 0 });
        cats = cats.map(menu=>{ return menu.id });
        for(let i=0; i<cats.length; i++) {
          this.$axios.get(url + `&page_num=1&page_length=3`, {
            params: {category: cats[i]}
          }).then(res=> {
            if(res.status === 200) {
              this.products[`products${i}`] = res.data;
            }
          })
        }
      },
      getProducts() {
        this.list = [];
        let category = '';
        if(this.filtered.selectedMenu && this.filtered.selectedMenu.id > 0) {
          category = `&category=${this.filtered.selectedMenu.id}`;
        } /*else {
          params = {category: '1,19'};
        }*/
        this.currentPage = 1;
        let fields = '&fields=id,name,simple_desc,img,price,partner.img,partner.name,category1,category2,preview_url';
        this.url = `user/0/mapping/product?${category}${fields}&page_num=1&page_length=9`
        this.$axios.get(this.url).then(res => {
          if (res.status === 200) {
            this.totalPages = res.data.total_page;
            this.totalCount = res.data.count;
            this.currentPage = 2;
            this.list = res.data.data;
          }
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  $main = #242428
  $sub = #333333
  $sub2 = #4F4F4F
  $sub3 = #828282
  $sub4 = #AEAEAE
  $sub5 = #BDBDBD
  $gray = #DDDDDD
  $gray1 = #E3E3E3
  $gray2 = #EEEEEE
  $gray3 = #F5F5F5
  $gray4 = #FAFAFA
  $primary = #FF6600
  $primary-dark = #EA5400
  $primary-light1 = #FFA366
  $primary-light2 = #FFF0E5
  $error = #FF3B3B
  $warning = #FFCC00
  $success = #06C270
  $info = #0063F7
  $page-width = 1152px

  .cat-selected
    position absolute
    left 0
    bottom 0
    width 100%
    background-color $primary
    height 3px

  .cat-wrapper
    overflow-x scroll
    -ms-overflow-style none /* IE and Edge */
    scrollbar-width none /* Firefox */

  .cat-wrapper::-webkit-scrollbar
    display none /* Chrome, Safari, Opera*/

  .cat-st
    flex 0 0 auto
    position relative

  .pc
    .cat-wrapper
      border-bottom 1px solid $gray1
    .cat-st
      padding 20px 24px
      position relative
    .cat-wrapper
      display flex
      align-items center
      justify-content center

    .product-item-wrapper
      border-bottom 1px solid $gray1
      padding-bottom 56px

    .product-item-wrapper:last-child
      border-bottom 0

  .tablet
    .cat-st
      padding 12px 16px
    .cat-wrapper
      display flex
      align-items center
      border-bottom 1px solid $gray1
    .mobile-more-btn
      background-color $gray3
      color $sub2
      border-radius 8px
      border 0
      margin-top 24px

  .mobile
    .cat-st
      padding 12px 16px
    .cat-wrapper
      display flex
      align-items center
      border-bottom 1px solid $gray1
    .mobile-more-btn
      background-color $gray3
      color $sub2
      border-radius 8px
      border 0
      margin-top 24px

  .banner
    background-color $gray4
    height 320px
    position relative
    overflow hidden
    color $main

  .banner-content
    position absolute
    width $page-width
    left 50%
    transform translateX(-50%)
    display flex
    height 100%
    z-index 1

  .circle
    opacity 0.15
    background linear-gradient(91deg, #7b61ff, transparent)
    position absolute
    top -50px
    left -603px
    width $page-width
    height $page-width

  @media (max-width:1024px)
    .pc
      display none
    .tablet
      display block
    .mobile
      display none
    .banner
      height 160px
      padding 28px 0
    .banner-content
      display block
      width 100%
      text-align center
    .circle
      width 370px
      height 370px
      top -44px
      left -50px
      background linear-gradient(91deg, #7b61ff, transparent)
  @media (max-width:640px)
    .pc
      display none
    .tablet
      display none
    .mobile
      display block
</style>
